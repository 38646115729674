<ng-template #template>
  <div class="row mt-2">
    <div class="col-md-12">
      <div class="card audit-card">
        <div class="container">
          <div class="row header mt-4">
            <div class="col-md-4 mt-3">
              <div>
                <h4>
                  <b>{{ 'MASTER_MENU.HISTORY' | translate }}</b>
                </h4>
              </div>
            </div>

            <div class="col-md-8 d-flex flex-row-reverse">
              <div class="mt-4">
                <button class="reset_button" (click)="handleSearch()">
                  <mat-icon class="search-icons">search</mat-icon></button
                >&nbsp; &nbsp;
                <button class="reset_button" (click)="handleReset()">
                  <mat-icon class="search-icons">restart_alt</mat-icon>
                </button>
              </div>
              &nbsp; &nbsp; &nbsp;
              <mat-form-field appearance="fill">
                <mat-label>{{ 'MASTER_MENU.SELECT_A_DATE_RANGE' | translate }}</mat-label>
                <mat-date-range-input
                  [formGroup]="range"
                  [rangePicker]="picker"
                  [max]="maxDate"
                  [min]="minDate"
                >
                  <input
                    matStartDate
                    formControlName="start"
                    placeholder="Start date"
                    readonly
                  />
                  <input
                    matEndDate
                    formControlName="end"
                    placeholder="End date"
                    readonly
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error
                  *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                  >{{ 'MASTER_MENU.INVALID_START_DATE' | translate }}</mat-error
                >
                <mat-error
                  *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                  >{{ 'MASTER_MENU.INVALID_END_DATE' | translate }}</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="isLoading" class="row">
          <div class="col-md-12 d-flex justify-content-center">
            <mat-spinner diameter="30"></mat-spinner>
            <!-- <app-loader></app-loader> -->
          </div>
        </div>
        <div class="body">
          <div class="container history-container" (scroll)="onScroll($event)">
            <div class="row">
              <div *ngIf="!isLoading && auditEventList?.items.length == 0" class="col-md-12 text-center">
                <h5>{{ 'NOTIFICATION_MENU.RECORDS_NOT_FOUND' | translate }}</h5>
              </div>
              <div *ngIf="!isLoading" class="col-md-12">
                <div *ngFor="let dailyAuditEvent of auditEventList?.items">
                  <div>
                    <b>{{ dailyAuditEvent?.key | date }}</b>
                  </div>
                  <ul class="left-timeline">
                    <li *ngFor="let auditEvent of dailyAuditEvent?.items">
                      <time class="left-time"
                        ><span>{{
                          auditEvent.eventTime | date : 'HH:mm a'
                        }}</span>
                      </time>
                      <div>
                        <img
                          class="left-icon"
                          src="https://fleetblobcz.blob.core.windows.net/fmp/img_avatar.png"
                          alt=""
                        />
                      </div>
                      <div class="left-label">
                        <div *ngFor="let event of auditEvent?.changedProperties">
                          <span [innerHTML]="event"></span>
                        </div>

                        <h2>
                          <a class="no-pointer">by {{ auditEvent.changedBy }} </a>
                        </h2>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-auto"/>
    <div class="filter-close-div">
      <!-- <button mat-flat-button color="primary" class="flat-info-btn btn-text-size" (click)="applyFilter()">
        {{'FILTER_MENU.APPLY_FILTER' | translate}}<mat-icon>add</mat-icon>
    </button>&nbsp;&nbsp; -->
    <button type="button" mat-stroked-button color="primary" class="btn-border outline-info-btn btn-text-size" (click)="modalRef.hide()">{{'FILTER_MENU.CLOSE' | translate}}</button>&nbsp;&nbsp;
    </div>
</ng-template>
