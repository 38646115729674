import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuditEvent, AuditEventPaginated } from 'src/app/shared/models/common-data.model'
import { BaseDataService } from 'src/app/core/service/base-data.service';

@Injectable({
  providedIn: 'root'
})
export class CommonTimelineService {

  private apiAuditEventUrl = 'api/AuditEvent'

  constructor(private baseDataService: BaseDataService) { }

  getAuditHistoryByEntity(
    auditEvent: AuditEvent
  ): Observable<AuditEventPaginated> {
    return this.baseDataService.makePostCall(
      `${this.apiAuditEventUrl}/getAuditHistoryByEntity`,
      auditEvent
    );
  }
}
