import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PlatesSharedService {
  constructor() { }

  private subjectOpenPlateAddModal = new BehaviorSubject(null);
  private subjectOpenPlateEditModal = new BehaviorSubject(null);
  private subjectOpenPlateTableChanged = new BehaviorSubject(null);


  setsubjectOpenPlateAddModal(string: string) {
    this.subjectOpenPlateAddModal.next(string);
  }
  setsubjectOpenPlateTableChanged(flag: boolean) {
    this.subjectOpenPlateTableChanged.next(flag);
  }
  getsubjectOpenPlateTableChanged(): Observable<any> {
    return this.subjectOpenPlateTableChanged.asObservable();
  }
  setsubjectOpenPlateEditModal(id: string) {
    this.subjectOpenPlateEditModal.next(id);

  }

  getsubjectOpenPlateAddModal(): Observable<any> {
    return this.subjectOpenPlateAddModal.asObservable();
  }

  getsubjectOpenPlateEditModal(): Observable<any> {
    return this.subjectOpenPlateEditModal.asObservable();
  }

}
