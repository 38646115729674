<div class="tab-form-container" id="document-container">
    <form [formGroup]="documentAttachmentForm">
        <div class="row  mt-3">
            <div class="col-md-3">
                <h6 class="mb-3" *ngIf="!isEditMode">{{'ISSUES.MULTIPLE_ATTACHMENT' | translate }}</h6>
                <h6 class="mb-3" *ngIf="isEditMode"><b>{{'MASTER_MENU.EDIT_ATTACHMENT' | translate }}</b></h6>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="example-full-width form-input-field">
                    <mat-label> {{'CONTACT_MENU.DOCUMENT_NAME' | translate}}</mat-label>
                    <input formControlName="AttachmentName" type="text" matInput pattern="\S(.*\S)?" required>
                </mat-form-field>
            </div>
            <div class="col-md-6 d-flex" *ngIf="!isNewAtt">
                <div class="btn-add-flex toggle-container pt-3">
                    <mat-slide-toggle formControlName="Status"></mat-slide-toggle>
                </div>
                <div class="btn-add-flex toggle-container pt-3 pr-2">
                    <mat-label class="text-active">{{'MASTER_MENU.DOCUMENT_STATUS' | translate}}</mat-label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
            <mat-form-field class="example-full-width form-input-field">
                <mat-label> {{'ISSUES.CATEGORY' | translate}}</mat-label>
                <mat-select [formControlName]="'AttachmentCategory'" #singleSelect>
                <mat-option>
                    <ngx-mat-select-search placeholderLabel="{{'MASTER_MENU.SEARCH' | translate}}" noEntriesFoundLabel="{{'MASTER_MENU.NO_RESULTS_FOUND' | translate}}" [(ngModel)]="categorySearch" [ngModelOptions]="{standalone: true}"  ></ngx-mat-select-search>
                </mat-option>
                <mat-option class="form-select-option" *ngFor="let category of categoryList| filter: categorySearch" [value]="category.value">
                    {{category.name}}
                </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10">
            <mat-form-field class="example-full-width form-input-field">
                <mat-label>{{ 'ISSUES.ATTACHMENT_DESCRIPTION' | translate }}</mat-label>
                <input type="text" matInput formControlName="AttachmentDescription">
            </mat-form-field>
            </div>
        </div>
        <div class="row">

        </div>
        <div class="row">
            <div class="col-md-10">
                <div *ngIf="currentUrl == '' || currentUrl == null">
                    <app-file-upload id="doc-upload" [headerName]=this.uploadDocHeaderName (change)="onDocumentFileSelected($event, 2)"></app-file-upload>
                </div>
                <div *ngIf="currentUrl != '' && currentUrl != null">
                    <div class="text-center mb-2">
                        <a href="{{currentUrl}}" target="_blank" rel="noopener noreferrer">{{ 'MANAGE_ATTACHMENT.CLICK_HERE_TO_VIEW_DOCUMENT' | translate }}</a>
                        <!-- <img src="{{currentUrl}}" alt="Avatar" class="image mx-auto"> -->
                    </div>
                    <div class="delete-btn text-center" *ngIf="currentUrl != '' && currentUrl != null">
                        <button mat-raised-button color="warn" (click)="deleteImg()">{{ 'MANAGE_ATTACHMENT.DELETE_DOCUMENT' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row  mt-3">
            <div class="col-md-12">
                <div class="row  mt-3">
                    <div *ngIf="!isEditMode" class="col-md-12 d-flex justify-content-end mb-3">
                        <button
                        mat-flat-button
                        class="btn-text-size mt-2 ml-5 bg-Info"
                        color="primary"
                        (click)="addAttachment()"
                        [disabled]="!documentAttachmentForm.valid || currentUrl == ''"
                    >
                       {{ 'MASTER_MENU.ADD_ATTACHMENT_RECORD' | translate }}
                        <mat-icon>add</mat-icon>
                    </button>
                    </div>
                    <div *ngIf="isEditMode" class="col-md-12 d-flex justify-content-end mb-3">
                        <button
                        mat-flat-button
                        class="btn-text-size mt-2 ml-5 bg-status-draft edit-att-btn btn-space"
                        color="primary"
                        (click)="clearEdit()"
                        [disabled]="!documentAttachmentForm.valid || currentUrl == ''"
                    >
                       {{ 'MASTER_MENU.CANCEL_EDIT_ATTACHMENT' | translate }}
                        <mat-icon>cancel</mat-icon>
                        </button>
                        <button
                        mat-flat-button
                        class="btn-text-size mt-2 ml-5 bg-Info btn-space"
                        color="primary"
                        (click)="editAttachment()"
                        [disabled]="!documentAttachmentForm.valid || currentUrl == ''"
                    >
                       {{ 'MASTER_MENU.EDIT_ATTACHMENT_RECORD' | translate }}
                        <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </div>
            <table mat-table [dataSource]="dataSource" class="fleet-table">
                <ng-container matColumnDef="documentName">
                    <th mat-header-cell *matHeaderCellDef>{{ 'CONTACT_MENU.DOCUMENT_NAME' | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element?.documentName}} </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef>{{'ISSUES.CATEGORY' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{getCategory(element?.category)}} </td>
                </ng-container>
                <ng-container matColumnDef="attachmentDescription">
                    <th mat-header-cell *matHeaderCellDef>{{ 'ISSUES.ATTACHMENT_DESCRIPTION' | translate }}</th>
                    <td mat-cell *matCellDef="let element"> {{element?.description}} </td>
                </ng-container>
                <ng-container matColumnDef="link">
                <th mat-header-cell *matHeaderCellDef class="pl-2">{{ 'ISSUES.LINK' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <a href="{{element?.documentUrl}}" target="_blank" rel="noopener noreferrer"><span class="material-icons-outlined">link</span></a>
                    <!-- <a href="{{element?.documentUrl}}" target="_blank" rel="noopener noreferrer">{{element?.documentUrl}}</a> -->
                </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{ 'MASTER_MENU.DOCUMENT_STATUS' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge bg-green" *ngIf="element.isActive">{{'MASTER_MENU.ACTIVE' | translate}}</span>
                        <span class="badge bg-grey" *ngIf="!element.isActive">{{'MASTER_MENU.INACTIVE' | translate}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> {{ 'ISSUES.ACTION' | translate }}</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <a class="col-grey" (click)="editRecord(i)"><span class="material-icons-outlined" >edit</span></a>
                    <a class="col-red" (click)="removeAttachment(i)"><span class="material-icons-outlined">delete</span></a>
                </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
            </table>
            <div>

            </div>
            <mat-paginator
                class="customPaginator"
                [pageSizeOptions]="pageSizeOptions"
                [pageSize]="20"
                showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
            </div>
        </div>
    </form>
</div>
