<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
        <img src="../../../assets/images/Osos/osos-logo.svg" alt="" height="38px" *ngIf="!isNavbarCollapsed" />
        <img src="../../../assets/images/Osos/osos_logo_icon_square_white.png" alt="" height="38px"
          *ngIf="isNavbarCollapsed" />

        <!-- <span class="logo-name">  Fleet Management V 1.0</span> -->
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-button [routerLink]="'dashboard'" class="sidemenu-collapse nav-notification-icons"> <i
              class="material-icons-outlined icon-color">
              home
            </i></button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-button class="nav-notification-icons" (click)="callFullscreen()"> <i
              class="material-icons-outlined icon-color">
              fullscreen
            </i></button>
        </li>
        <!-- #END# Full Screen Button -->
        <!-- #START# Notifications-->
        <li>
          <button mat-button [matMenuTriggerFor]="notificationMenu" class="nav-notification-icons"
            (click)="bellClick(isShowUnread)"> <i class="material-icons-outlined icon-color">
              notifications_active
            </i>
            <span class="badge bg-red notification-badge">{{unreadCount > 99 ? '99+': unreadCount}}</span>
          </button>

          <mat-menu #notificationMenu="matMenu" class="nfc-menu" xPosition="before" [hasBackdrop]="true">
            <div class="nfc-header" (click)="$event.stopPropagation()">
              <h5 class="mb-0">{{'NOTIFICATION_MENU.NOTIFICATIONS' | translate}}</h5>
              <!-- <a (click)="openNotificationSettingModal()" class="nfc-mark-as-read"><i
                  class="material-icons-outlined icon-color">
                  settings
                </i></a> -->
              <div>

              </div>

            </div>
            <div class="row p-2 btn-heading" (click)="$event.stopPropagation()">
              <div class="col-md-6">
                <a (click)="markAllAsRead()" class="btn btn-sm btn-outline-primary">{{'NOTIFICATION_MENU.MARK_ALL_AS_READ' | translate}}</a>
              </div>
              <div class="col-md-6 ">
                <!-- (change)="showRead($event)" -->
                <form [formGroup]="notificationForm">
                  <mat-slide-toggle formControlName="isUnread" (change)="showUnreadRead($event)"><span style="font-size: 11px;" >{{'NOTIFICATION_MENU.SHOW_ONLY_UNREAD' | translate}}</span></mat-slide-toggle>
                </form>

              </div>
            </div>
            <div class="nfc-dropdown" >
              <ng-scrollbar style="height: 350px" visibility="hover">
                <div class="noti-list header-menu">
                  <div class="menu">
                    <div (scroll)="onScroll($event)" class="item-container">
                      <p class="text-center mt-2" *ngIf="!isLoading && notificationList.length == 0">{{'NOTIFICATION_MENU.NOTIFICATIONS_NOT_FOUND' | translate}}</p>
                      <button mat-menu-item *ngFor="let notification of notificationList" onClick="return false;"
                      [ngClass]="{'msg-unread':!notification.isRead}" (click)="handleNotification(notification.id)">
                        <span class="table-img msg-user ">
                          <i class="material-icons-two-tone nfc-type-icon"
                            [ngClass]="{'nfc-green':true}">{{notification.icon ?? 'notifications_active'}}</i>
                        </span>
                        <span class="menu-info">
                          <span class="menu-title">{{this.langStoreValue == 'ar' ? notification?.secondryMessage : notification?.message}}</span>
                          <span class="menu-desc mt-2">
                            <i class="material-icons">access_time</i> {{getFromNow(notification.timeStamp)}} ago
                          </span>
                        </span>
                        <span class="nfc-close">
                        </span>
                      </button>
                    </div>
                    <app-loader *ngIf="isLoading"></app-loader>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
            <!-- <div class="nfc-footer">
              <a class="nfc-read-all">{{'NOTIFICATION_MENU.READ_ALL_NOTIFICATIONS' | translate}}</a>
            </div> -->
          </mat-menu>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item">
          <button mat-button [matMenuTriggerFor]="languagemenu" class="lang-dropdown nav-notification-icons"> <img
              *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">
            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16"></button>
          <mat-menu #languagemenu="matMenu">
            <div *ngFor="let item of listLang" class="lang-item">
              <button mat-menu-item class="dropdown-item lang-item-list"
                (click)="setLanguage(item.text, item.lang, item.flag)"
                [ngClass]="{'active': langStoreValue === item.lang}">
                <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>
              </button>
            </div>
          </mat-menu>
        </li>
        <li class="nav-item company-select-nav">
          <button mat-button [matMenuTriggerFor]="companymenu" class="lang-dropdown nav-notification-icons company-select-btn"> <img
              *ngIf="currentCompany !== undefined" src="{{currentCompany?.image ? currentCompany?.image : '../../../assets/images/company-default.png'}}" height="16" class="company-img">
            <img *ngIf="currentCompany === undefined" src="{{defaultFlag}}" height="16" class="company-img"> {{currentCompany?.name}}</button>
          <mat-menu #companymenu="matMenu" class="company-menu" style="max-height: 500px !important;">
            <div class="w-100">
              <mat-spinner class="mx-auto" *ngIf="isCompaniesLoading" diameter="20"></mat-spinner>
            </div>

            <div class="div-lst">

              <div *ngFor="let item of this.companyLookup" class="lang-item">
                <button mat-menu-item class="dropdown-item lang-item-list company-btn"
                (click)="setCompany(item)"
                [ngClass]="{'active': currentCompany?.id === item?.id}">
                <img src="{{item?.image ? item?.image : '../../../assets/images/company-default.png'}}" class="company-img" height="12"> <span class="align-middle"> {{item.name}}</span>
              </button>
            </div>
          </div>
          </mat-menu>
        </li>
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle" ngbDropdownToggle class="pt-0">
              <img src="../../../assets/images/user/img_avatar.png" class="user_img" width="32" height="32" alt="User">&nbsp;
              <span>{{ this.langStoreValue == 'ar' && this.userContactData?.secondaryName ? this.userContactData?.secondaryName : this.currentUser?.name ??''}}</span>
              <!-- <span>   {{'VEHICLE_MENU.FLEET_USER' | translate}}</span> -->
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu ">
                <div class="user_dw_menu">
                  <!-- <button mat-menu-item>
                    <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Account
                  </button>
                  <button mat-menu-item>
                    <app-feather-icons [icon]="'mail'" [class]="'user-menu-icons'"></app-feather-icons>Inbox
                  </button>
                  <button mat-menu-item>
                    <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
                  </button> -->
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons> {{ 'SIGNUP_MENU.LOG_OUT' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
  <app-notification-setting-modal></app-notification-setting-modal>
</nav>
