import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonTimelineSharedService {
  private subjectTimelineModal = new BehaviorSubject(null);

  constructor() { }

  setSubjectTimelineModal(str: string) {
    this.subjectTimelineModal.next(str);
  }
  getSubjectTimelineModal(): Observable<any> {
    return this.subjectTimelineModal.asObservable();
  }
}
