import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { FormBuilder, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { CommonImportSharedService } from 'src/app/shared/services/common-import-shared.service';
import { CommonImportService } from 'src/app/shared/services/common-import.service';
import { ExcelUploadRes } from 'src/app/shared/models/common-data.model';
import { VehicleSharedService } from 'src/app/home/vehicles/services/vehicle-shared.service';
import { ContactsSharedService } from 'src/app/home/contacts/services/contacts-shared.service';
import { FuelHistorySharedService } from 'src/app/home/fuel-history/services/fuel-history-shared.service';
import { PlatesSharedService } from 'src/app/home/master/plates/services/plates-shared.service';
@Component({
  selector: 'app-common-import-modal',
  templateUrl: './common-import-modal.component.html',
  styleUrls: ['./common-import-modal.component.scss'],
})
export class CommonImportModalComponent {
  @ViewChild('template', { static: true }) template: any;
  inquiryImportSubject: Subscription;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  modalRef: BsModalRef;
  isLoading: boolean = false;
  isDownloading: boolean = false;
  isUploading: boolean = false;
  formData: FormData = new FormData();
  selectedRadioButtonValue: number;
  excelUploadRes: ExcelUploadRes;
  isExcelFileSelected: boolean = false;
  firstFormGroup = this.fb.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this.fb.group({
    secondCtrl: ['', Validators.required],
  });
  isEditable = false;
  moduleName: string = '';
  constructor(
    private modalService: BsModalService,
    private translateService: TranslateService,
    private helpersService: HelpersService,
    private commonImportSharedService: CommonImportSharedService,
    private fb: FormBuilder,
    private commonImportService: CommonImportService,
    private vehicleSharedService: VehicleSharedService,
    private contactsSharedService: ContactsSharedService,
    private fuelHistorySharedService: FuelHistorySharedService,
    private platesSharedService: PlatesSharedService
  ) {
    this.inquiryImportSubject = commonImportSharedService
      .getSubjectImportModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((modeType: any) => {
        if (modeType != null) {
          this.moduleName = modeType;
          this.showModal();
        }
      });
  }

  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-centered modal-lg',
    });
  }

  getHeader() {
    let headerName = '';
    switch (this.moduleName) {
      case 'VEHICLE':
        headerName = 'VEHICLE_MENU.IMPORT_VEHICLE';
        break;
      case 'CONTACT':
        headerName = 'CONTACT_MENU.IMPORT_CONTACTS';
        break;
      case 'FUEL_HISTORY':
        headerName = 'FUEL_HISTORY_MENU.IMPORT_FUEL_HISTORY';
        break;
      case 'PLATE':
        headerName = 'MASTER_MENU.IMPORT_PLATE_MASTER';
        break;
      default:
        headerName = '';
        break;
    }
    return headerName;
  }

  getDownloadTemplateFunction(isTemplateEmpty: boolean) {
    let tempFunction: any;
    switch (this.moduleName) {
      case 'VEHICLE':
        tempFunction =
          this.commonImportService.vehicleTemplateDownload(isTemplateEmpty);
        break;
      case 'CONTACT':
        tempFunction =
          this.commonImportService.contactTemplateDownload(isTemplateEmpty);
        break;
      case 'FUEL_HISTORY':
        tempFunction =
          this.commonImportService.fuelHistoryTemplateDownload(isTemplateEmpty);
        break;
      case 'PLATE':
        tempFunction =
          this.commonImportService.plateMasterTemplateDownload(isTemplateEmpty);
        break;
      default:
        tempFunction = null;
        break;
    }
    return tempFunction;
  }

  getExcelUploadFunction() {
    let tempFunction: any;
    switch (this.moduleName) {
      case 'VEHICLE':
        tempFunction = this.commonImportService.vehicleUploadExcel(
          this.formData,
          this.selectedRadioButtonValue
        );
        break;
      case 'CONTACT':
        tempFunction = this.commonImportService.contactUploadExcel(
          this.formData,
          this.selectedRadioButtonValue
        );
        break;
      case 'FUEL_HISTORY':
        tempFunction = this.commonImportService.fuelHistoryUploadExcel(
          this.formData,
          this.selectedRadioButtonValue
        );
        break;
      case 'PLATE':
        tempFunction = this.commonImportService.plateMasterUploadExcel(
          this.formData,
          this.selectedRadioButtonValue
        );
        break;
      default:
        tempFunction = null;
        break;
    }
    return tempFunction;
  }

  getTableRefreshFunction() {
    let tempFunction: any;
    switch (this.moduleName) {
      case 'VEHICLE':
        tempFunction =
          this.vehicleSharedService.setSubjectVehicleTableChanged(true);
        break;
      case 'CONTACT':
        tempFunction =
          this.contactsSharedService.setSubjectContactTableChanged(true);
        break;
      case 'FUEL_HISTORY':
        tempFunction =
          this.fuelHistorySharedService.setSubjectFuelHistoryTableChanged(true);
        break;
      case 'PLATE':
        tempFunction =
          this.platesSharedService.setsubjectOpenPlateTableChanged(true);
        break;
      default:
        tempFunction = null;
        break;
    }
    return tempFunction;
  }

  getFileName() {
    let tempName: string;
    switch (this.moduleName) {
      case 'VEHICLE':
        tempName = 'Vehicle_Bulk_Upload_Template_';
        break;
      case 'CONTACT':
        tempName = 'Contact_Bulk_Upload_Template_';
        break;
      case 'FUEL_HISTORY':
        tempName = 'Fuel_History_Bulk_Upload_Template_';
        break;
      case 'PLATE':
        tempName = 'Plate_Master_Bulk_Upload_Template_';
        break;
      default:
        tempName = '';
        break;
    }
    return tempName;
  }

  downloadInquiryTemplate(isTemplateEmpty: boolean) {
    this.isDownloading = true;
    let fileDate = moment().format('YYYY_MM_DDTHH:mm');
    let fileName = this.getFileName() + fileDate + '.xlsx';

    this.getDownloadTemplateFunction(isTemplateEmpty)
      .pipe(takeUntil(this.destroy))
      .subscribe(
        (res) => {
          FileSaver.saveAs(res, fileName);
          this.helpersService.showDownloadSuccessToast();
        },
        (error) => {
          this.isDownloading = false;
        }
      );
  }

  excelUpload() {
    this.isUploading = true;
    this.getExcelUploadFunction().pipe(takeUntil(this.destroy))
      .subscribe(
        (excelUploadRes: ExcelUploadRes) => {
          this.isUploading = false;
          this.excelUploadRes = excelUploadRes;
          if (excelUploadRes.excelUrl) {
            setTimeout(()=>{
              this.getTableRefreshFunction();
            },500)
          }
        },
        (error) => {
          this.isUploading = false;
        }
      );
  }

  onExcelFileSelected(event: any) {
    let fileList = (FileList = event.target.files);
    if (fileList.length > 0) {
      let file: File = fileList[0];
      this.formData.append('excelFile', file, file.name);
      this.isExcelFileSelected = true;
      this.firstFormGroup.get('firstCtrl').setValue('fileSelected');
    }
  }

  cancelStepper() {
    this.modalRef.hide();
    this.isExcelFileSelected = false;
    this.selectedRadioButtonValue = null;
    this.formData = new FormData();
    this.moduleName = '';
    this.firstFormGroup.get('firstCtrl').setValue('');
  }

  ngOnDestroy(): void {
    this.commonImportSharedService.setSubjectImportModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
    this.formData = new FormData();
  }
}
