import { Component, ViewChild, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { CommonTimelineService } from 'src/app/shared/services/common-timeline.service'
import { CommonTimelineSharedService } from 'src/app/shared/services/common-timeline-shared.service'
import { AuditEvent, AuditEventPaginated } from 'src/app/shared/models/common-data.model'
import * as moment from 'moment';

@Component({
  selector: 'app-common-timeline-modal',
  templateUrl: './common-timeline-modal.component.html',
  styleUrls: ['./common-timeline-modal.component.scss']
})
export class CommonTimelineModalComponent {
  @ViewChild('template', { static: true }) template: any;
  modalRef: BsModalRef;
  auditEvent: AuditEvent;
  pageIndex: number = 1;
  pageSize: number = 25;
  entityId: string;
  isLoading: boolean = false;
  maxDate: Date = new Date();
  auditEventList: AuditEventPaginated;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  minDate: Date = moment().subtract(6, 'months').toDate();

  range = new FormGroup({
    start: new FormControl<Date | null>(
      moment().startOf('day').subtract(7, 'days').toDate()
    ),
    end: new FormControl<Date | null>(moment().startOf('day').toDate()),
  });

  constructor(
    private modalService: BsModalService,
    private translateService: TranslateService,
    private helpersService: HelpersService,
    private commonTimelineService: CommonTimelineService,
    private commonTimelineSharedService: CommonTimelineSharedService,
  ) {
    this.commonTimelineSharedService.getSubjectTimelineModal()
    .pipe(takeUntil(this.destroy)).subscribe((eId: string)=>{
      if(eId != null) {
        if(this.auditEventList?.items) {
          this.auditEventList.items = [];
        }
        this.entityId = eId;
        this.showModal()
        this.getAuditHistoryByEntity();
      }
    })
  }
  private showModal() {
    this.modalRef = this.modalService.show(this.template, {
      class: 'modal-dialog-right modal-lg audit-modal',
    });
  }

  getAuditHistoryByEntity() {
    this.auditEvent = {
      entityId: this.entityId,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      startDate: this.helpersService.setDateForDb(this.range.value.start),
      endDate: this.helpersService.setDateForDb(this.range.value.end),
    };

    if (this.range.value.start && this.range.value.end) {
      this.isLoading = true;

      this.commonTimelineService
        .getAuditHistoryByEntity(this.auditEvent)
        .pipe(takeUntil(this.destroy))
        .subscribe(
          (auditEventList: AuditEventPaginated) => {
            this.isLoading = false;
            if (this.pageIndex == 1) {
              this.auditEventList = auditEventList;
            } else {
              auditEventList.items.forEach((newElement) => {
                var index = this.auditEventList.items
                  .map((x) => x.key)
                  .indexOf(newElement.key);
                if (index != -1) {
                  this.auditEventList.items[index].items.push(
                    ...newElement.items
                  );
                } else {
                  this.auditEventList.items.push(newElement);
                }
              });
            }
          },
          () => {
            this.isLoading = false;
          }
        );
    }
  }

  handleSearch() {
    this.pageIndex = 1;
    this.getAuditHistoryByEntity();
  }

  handleReset() {
    this.pageIndex = 1;
    this.range = new FormGroup({
      start: new FormControl<Date | null>(
        moment().startOf('day').subtract(7, 'days').toDate()
      ),
      end: new FormControl<Date | null>(moment().startOf('day').toDate()),
    });
    this.getAuditHistoryByEntity();
  }

  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      // User has scrolled to the bottom
      if (this.auditEventList?.totalItems > this.pageSize * this.pageIndex) {
        this.pageIndex++;
        this.getAuditHistoryByEntity();
      }
    }
  }

  ngOnDestroy(): void {
    this.commonTimelineSharedService.setSubjectTimelineModal(null);
    this.modalRef?.hide();
    this.destroy.next(null);
  }

}
