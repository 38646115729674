<ng-template #template>
  <div class="rest-icon"></div>

  <div class="row">
    <h6 class="pull-left filter-modal-title col-md-4">
      <b>{{ 'FILTER_MENU.FILTER_BY' | translate }}</b>
    </h6>
    <div class="col-md-4 modal-title text-end">
      <a
        (click)="addNewFilterValue()"
        style="cursor: pointer"
        *ngIf="
          getFilterValues?.controls?.length != coloumnsData.length &&
          isLastFilterValid()
        "
        ><span
          class="text-success material-icons-outlined filter-btn-icon icon-font-size"
          ><mat-icon>add</mat-icon></span
        ></a
      >
      <a style="cursor: pointer" (click)="resetFilter()">
        <span class="text-end"
          ><i class="material-icons-outlined filter-btn-icon icon-font-size"
            >restart_alt</i
          ></span
        ></a
      >
    </div>
  </div>
  <div class="modal-body p-2">
    <div class="col-12 mt-3 filter-container">
      <form
        [formGroup]="filterForm"
        (keydown.enter)="applyFilter()"
        class="filter-form"
      >
        <div formArrayName="Filters">
          <div
            *ngFor="let listItem of getFilterValues?.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="row mt-3">
              <div class="col-md-9 filter-card-col">
                <mat-accordion class="example-headers-align" multi>
                  <mat-expansion-panel
                    [expanded]="listItem.get('IsActive').value"
                    [disabled]="!listItem.get('IsActive').value"
                  >
                    <mat-expansion-panel-header [collapsedHeight]="'130px'">
                      <div class="display-header">
                        <mat-slide-toggle
                          formControlName="IsActive"
                        ></mat-slide-toggle>
                        <span
                          class="text-success"
                          style="cursor: pointer"
                          (click)="addNewFilterValue()"
                          *ngIf="
                            isFilterValid(i) &&
                            getFilterValues?.controls?.length !=
                              coloumnsData.length &&
                            i == getFilterValues?.controls?.length - 1
                          "
                          ><mat-icon>add</mat-icon></span
                        >
                        <span
                          class="text-danger"
                          style="cursor: pointer"
                          *ngIf="i != 0"
                          (click)="removeFilterValue(i)"
                          ><mat-icon>close</mat-icon></span
                        >
                      </div>
                      <ul class="mt-3">
                        <li>
                          <b>
                            {{ 'FILTER_MENU.FILTER_BY' | translate }} : {{ '' }}
                          </b>
                          {{
                            getFilterValues?.controls[i]?.get('Translate').value
                              | translate
                          }}
                        </li>
                        <li>
                          <b> {{ 'FILTER_MENU.CONDITION' | translate }} : </b>
                          {{
                            getFilterValues?.controls[i]?.get('ConditionText')
                              .value
                          }}
                        </li>
                        <li
                          class="list-value-li"
                          [hidden]="
                            listItem.get('Condition').value == 'IsEmpty' ||
                            listItem.get('Condition').value == 'IsNotEmpty' ||
                            listItem.get('Condition').value == 'Between' ||
                            (listItem.get('DataType').value == 'DATE' && listItem.get('Condition').value == 'IsAnyOf')
                          "
                        >
                          <b>{{ 'FILTER_MENU.VALUE' | translate }} : </b>
                          {{
                            (listItem.get('DataType').value == 'LOOKUP' &&
                              allowLookup.includes(
                                listItem.get('Condition').value
                              )) ||
                            (listItem.get('DataType').value == 'NUMBERLOOKUP' &&
                              allowLookup.includes(
                                listItem.get('Condition').value
                              ))
                              ? joinValue
                              : listItem.get('DataType').value == 'DATE'
                              ? (getFilterValues?.controls[i]?.get('Value')
                                  .value | date : 'dd-MM-yyyy')
                              : listItem.get('DataType').value == 'DATE' &&
                                allowLookup.includes(
                                  listItem.get('Condition').value
                                )
                              ? joinDates
                              : getFilterValues?.controls[i]?.get('Value').value
                          }}
                        </li>
                        {{
                          joinDates
                        }}
                        <li
                        class="list-value-li"
                        *ngIf="listItem.get('DataType').value == 'DATE' &&
                        listItem.get('Condition').value == 'IsAnyOf'"
                        [hidden]="!listItem.get('DataType').value == 'DATE'"
                      >
                        <b>{{ 'FILTER_MENU.VALUE' | translate }} : </b>
                        <span
                                class="badge col-green me-2 mt-2 custom-badge"
                                *ngFor="
                                  let date of this.getFilterValues.controls[
                                    i
                                  ]?.get('Dates')?.value;
                                  let idx = index
                                "
                              >
                                {{ date | date : 'dd-MM-yyyy' }}
                              </span>
                      </li>
                        <li
                          *ngIf="listItem.get('Condition').value == 'Between'"
                          [hidden]="listItem.get('DataType').value == 'DATE'"
                        >
                          <b>{{fromLabel}}:</b>
                          {{ getFilterValues?.controls[i]?.get('Value').value }}
                        </li>
                        <li
                          *ngIf="
                            listItem.get('Condition').value == 'Between' &&
                            listItem.get('DataType').value == 'DATE'
                          "
                        >
                          <b>{{fromLabel}}:</b>
                          {{
                            getFilterValues?.controls[i]?.get('Value').value
                              | date : 'dd-MM-yyyy'
                          }}
                        </li>
                        <li
                          *ngIf="listItem.get('Condition').value == 'Between'"
                          [hidden]="listItem.get('DataType').value == 'DATE'"
                        >
                          <b>{{toLabel}}:</b>
                          {{
                            getFilterValues?.controls[i]?.get('SecondaryValue')
                              .value
                          }}
                        </li>
                        <li
                          *ngIf="
                            listItem.get('Condition').value == 'Between' &&
                            listItem.get('DataType').value == 'DATE'
                          "
                        >
                          <b>{{toLabel}}:</b>
                          {{
                            getFilterValues?.controls[i]?.get('SecondaryValue')
                              .value | date : 'dd-MM-yyyy'
                          }}
                        </li>
                      </ul>
                      <!-- <mat-icon>account_circle</mat-icon> -->
                      <!-- <mat-panel-description>


                                            </mat-panel-description> -->
                    </mat-expansion-panel-header>
                    <div
                      class="{{
                        !listItem.get('IsActive').value ? 'disabled-card' : ''
                      }} "
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-3"></div>
                          <div class="col-md-9">
                            <h5 class="card-title text-end"></h5>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12" *ngIf="i > 0">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label></mat-label>
                              <mat-select
                                formControlName="FilterClause"
                                class="disabled"
                                (selectionChange)="filterByChange($event, i)"
                              >
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let clause of listItem.get(
                                      'FilterClauseArray'
                                    ).value
                                  "
                                  [value]="clause?.id"
                                >
                                  {{ clause?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-md-12">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label
                                ><b>
                                  {{ 'FILTER_MENU.FILTER_BY' | translate }}</b
                                ></mat-label
                              >
                              <mat-select
                                formControlName="FilterBy"
                                class="disabled"
                                (selectionChange)="filterByChange($event, i)"
                              >
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let coloumn of listItem.get('ColoumnsArray')
                                      .value
                                  "
                                  [value]="coloumn?.data"
                                >
                                  {{
                                    coloumn?.translate | translate
                                  }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-md-12">
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label
                                ><b>
                                  {{ 'FILTER_MENU.CONDITION' | translate }}</b
                                ></mat-label
                              >
                              <mat-select
                                formControlName="Condition"
                                (selectionChange)="conditionChange($event, i)"
                              >
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let condition of listItem.get(
                                      'ConditionArray'
                                    ).value
                                  "
                                  [value]="condition?.id"
                                >
                                  {{ condition?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-md-12
                          {{listItem.get('DataType').value == 'LOOKUP' &&
                          listItem.get('Condition').value &&
                          (listItem.get('Condition').value == 'Equal' ||
                          listItem.get('Condition').value == 'NotEqual') ? 'd-none' : ''}}">
                            <mat-form-field
                              [hidden]="
                                listItem.get('DataType').value ==
                                  'NUMBERLOOKUP' ||
                                listItem.get('Condition').value == 'IsEmpty' ||
                                listItem.get('Condition').value == 'IsNotEmpty'
                              "
                              class="example-full-width form-input-field {{
                                ((listItem.get('DataType').value == 'LOOKUP' ||
                                  listItem.get('DataType').value !=
                                    'NUMBERLOOKUP') &&
                                  allowLookup.includes(
                                    listItem.get('Condition').value
                                  )) ||
                                listItem.get('DataType').value == 'DATE'
                                  ? 'd-none'
                                  : ''
                              }} "
                            >
                              <mat-label
                                ><b>
                                  {{
                                    listItem.get('Condition').value == 'Between'
                                      ? this.fromLabel
                                      : this.valueLabel
                                  }}
                                </b>
                              </mat-label>
                              <input
                                type="text"
                                formControlName="Value"
                                matInput
                                (keyup)="onKeyUp($event, i)"
                                class=""
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-md-12 {{
                            listItem.get('DataType').value == 'LOOKUP' &&
                              listItem.get('Condition').value &&
                              (listItem.get('Condition').value == 'Equal' ||
                            listItem.get('Condition').value == 'NotEqual')
                              ? ''
                              : 'd-none'
                          }}">
                            <mat-form-field
                            class="example-full-width form-input-field"
                            >
                              <mat-label
                                ><b>
                                  {{ this.valueLabel }}
                                </b>
                              </mat-label>
                              <mat-select
                                formControlName="Value"
                                class=""
                                (selectionChange)="getlookupvalue(i)"
                              >
                              <mat-option>
                                <ngx-mat-select-search placeholderLabel="{{'MASTER_MENU.SEARCH' | translate}}" noEntriesFoundLabel="{{'MASTER_MENU.NO_RESULTS_FOUND' | translate}}"    [(ngModel)]="lookupTypeSearch" [ngModelOptions]="{standalone: true}">
                                  </ngx-mat-select-search>
                              </mat-option>
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let condition of listItem.get('LookupArray')
                                      .value | filter: lookupTypeSearch
                                  "
                                  [value]="condition?.name"
                                >
                                  {{ condition?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div
                            *ngIf="
                              listItem.get('DataType').value == 'NUMBERLOOKUP' && listItem.get('Condition').value &&
                              (listItem.get('Condition').value != 'Equal' &&
                            listItem.get('Condition').value != 'NotEqual')
                            "
                            class="col-md-12"
                          >
                            <mat-form-field
                              class="example-full-width form-input-field {{
                                listItem.get('DataType').value ==
                                  'NUMBERLOOKUP' &&
                                allowLookup.includes(
                                  listItem.get('Condition').value
                                )
                                  ? 'd-none'
                                  : ''
                              }} "
                            >
                              <mat-label
                                ><b>
                                  {{
                                    listItem.get('Condition').value == 'Between'
                                      ? this.fromLabel
                                      : this.valueLabel
                                  }}
                                </b>
                              </mat-label>
                              <input
                                type="number"
                                formControlName="Value"
                                matInput
                                (keyup)="onKeyUp($event, i)"
                                class=""
                              />
                            </mat-form-field>
                          </div>





                          <div class="col-md-12 {{
                            listItem.get('DataType').value == 'NUMBERLOOKUP' &&
                              listItem.get('Condition').value &&
                              (listItem.get('Condition').value == 'Equal' ||
                            listItem.get('Condition').value == 'NotEqual')
                              ? ''
                              : 'd-none'
                          }}">
                            <mat-form-field
                            class="example-full-width form-input-field"
                            >
                              <mat-label
                                ><b>
                                  {{ this.valueLabel }}
                                </b>
                              </mat-label>
                              <mat-select
                                formControlName="Value"
                                class=""
                                (selectionChange)="getlookupvalue(i)"
                              >
                              <mat-option>
                                <ngx-mat-select-search placeholderLabel="{{'MASTER_MENU.SEARCH' | translate}}" noEntriesFoundLabel="{{'MASTER_MENU.NO_RESULTS_FOUND' | translate}}"    [(ngModel)]="lookupTypeSearch" [ngModelOptions]="{standalone: true}">
                                  </ngx-mat-select-search>
                              </mat-option>
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let condition of listItem.get('LookupArray')
                                      .value | filter: lookupTypeSearch
                                  "
                                  [value]="condition?.name"
                                >
                                  {{ condition?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div
                            class="col-md-12 {{
                              (listItem.get('DataType').value == 'LOOKUP' ||
                                listItem.get('DataType').value ==
                                  'NUMBERLOOKUP') &&
                              allowLookup.includes(
                                listItem.get('Condition').value
                              )
                                ? ''
                                : 'd-none'
                            }}"
                          >
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label
                                ><b>
                                  {{
                                    listItem.get('Condition').value == 'Between'
                                      ? fromLabel
                                      : valueLabel
                                  }}</b
                                >
                              </mat-label>
                              <mat-select
                                formControlName="LookupValue"
                                class=""
                                multiple
                                (selectionChange)="getlookupvalue(i)"
                              >
                              <mat-option>
                                <ngx-mat-select-search placeholderLabel="{{'MASTER_MENU.SEARCH' | translate}}" noEntriesFoundLabel="{{'MASTER_MENU.NO_RESULTS_FOUND' | translate}}"    [(ngModel)]="lookupTypeSearch" [ngModelOptions]="{standalone: true}">
                                  </ngx-mat-select-search>
                              </mat-option>
                                <mat-option
                                  class="form-select-option"
                                  *ngFor="
                                    let condition of listItem.get('LookupArray')
                                      .value | filter: lookupTypeSearch
                                  "
                                  [value]="condition?.id"
                                >
                                  {{ condition?.name }}</mat-option
                                >
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div
                            class="col-md-12"
                            *ngIf="
                              listItem.get('Condition').value == 'Between' &&
                              listItem.get('DataType').value != 'DATE'
                            "
                          >
                            <mat-form-field
                              class="example-full-width form-input-field"
                            >
                              <mat-label><b> {{toLabel}}</b></mat-label>
                              <input
                                type="text"
                                formControlName="SecondaryValue"
                                matInput
                                (keyup)="onKeyUp($event, i)"
                              />
                            </mat-form-field>
                          </div>

                          <div
                            class="col-md-12 {{
                              listItem.get('DataType').value == 'DATE'
                                ? ''
                                : 'd-none'
                            }}"
                          >
                            <mat-form-field
                              appearance="fill"
                              class="example-full-width form-input-field"
                              [hidden]="
                                listItem.get('Condition').value == 'IsAnyOf' &&
                                listItem.get('DataType').value == 'DATE'
                              "
                            >
                              <mat-label
                                *ngIf="
                                  listItem.get('Condition').value ==
                                    'Between' &&
                                  listItem.get('DataType').value == 'DATE'
                                "
                                ><b> {{fromLabel}}</b></mat-label
                              >
                              <input
                                readonly
                                matInput
                                [matDatepicker]="picker"
                                formControlName="Value"
                                placeholder=" {{
                                  'FILTER_MENU.CHOOSE_A_DATE' | translate
                                }}"
                                (dateChange)="addDate($event, i, true)"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field
                              appearance="fill"
                              class="example-full-width form-input-field"
                              *ngIf="
                                listItem.get('Condition').value == 'Between' &&
                                listItem.get('DataType').value == 'DATE'
                              "
                            >
                              <mat-label><b> {{toLabel}}</b></mat-label>
                              <input
                                readonly
                                matInput
                                [matDatepicker]="picker"
                                placeholder="{{
                                  'FILTER_MENU.CHOOSE_A_DATE' | translate
                                }}"
                                formControlName="SecondaryValue"
                                [min]="listItem.get('Value').value"
                                (dateChange)="addDate($event, i, false)"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <div
                              *ngIf="
                                listItem.get('Condition').value == 'IsAnyOf' &&
                                listItem.get('DataType').value == 'DATE'
                              "
                            >
                              <mat-form-field
                                appearance="fill"
                                class="example-full-width form-input-field"
                              >
                                <input
                                  readonly
                                  matInput
                                  [matDatepicker]="picker"
                                  placeholder="{{
                                    'FILTER_MENU.CHOOSE_A_DATE' | translate
                                  }}"
                                  formControlName="Value"
                                  (dateChange)="addDate($event, i, false)"
                                />
                                <mat-datepicker-toggle
                                  matSuffix
                                  [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                              <span
                                class="badge col-green me-2 mt-2 custom-badge"
                                *ngFor="
                                  let date of this.getFilterValues.controls[
                                    i
                                  ]?.get('Dates')?.value;
                                  let idx = index
                                "
                                (click)="removeDate(i, idx)"
                              >
                                {{ date | date : 'dd-MM-yyyy' }}
                                <mat-icon class="custom-icon">cancel</mat-icon>
                              </span>
                            </div>
                            <mat-chip-list>
                              <!-- <mat-chip
                                                              >

                                                            </mat-chip> -->
                            </mat-chip-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <hr />
  <div class="filter-close-div">
    <button
      mat-flat-button
      color="primary"
      class="flat-info-btn btn-text-size"
      (click)="applyFilter()"
    >
      {{ 'FILTER_MENU.APPLY_FILTER' | translate
      }}<mat-icon>add</mat-icon></button
    >&nbsp;&nbsp;
    <button
      type="button"
      mat-stroked-button
      color="primary"
      class="btn-border outline-info-btn btn-text-size"
      (click)="modalRef.hide()"
    >
      {{ 'FILTER_MENU.CLOSE' | translate }}</button
    >&nbsp;&nbsp;
  </div>
</ng-template>
