import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FuelHistorySharedService {
  private subjectFuelHistoryTableChanged = new BehaviorSubject(null);

  constructor() { }

  setSubjectFuelHistoryTableChanged(flag: boolean) {
    this.subjectFuelHistoryTableChanged.next(flag);
  }
  getSubjectFuelHistoryTableChanged(): Observable<any> {
    return this.subjectFuelHistoryTableChanged.asObservable();
  }
}
