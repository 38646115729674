import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from 'src/app/core/service/base-data.service';
import { BaseFilterVm } from 'src/app/shared/models/filter-data.model';
@Injectable({
  providedIn: 'root'
})
export class CommonImportService {

  private apiVehicleUrl = 'api/Vehicle';
  private apiContactUrl = 'api/Contact';
  private apiFuelHistoryUrl = 'api/FuelHistory';
  private apiPlateMasterUrl ='api/Plate'

  constructor(private baseDataService: BaseDataService) { }

  vehicleTemplateDownload(isTemplateEmpty: boolean): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiVehicleUrl}/vehicleTemplateDownload?isTemplateEmpty=${isTemplateEmpty}`,
      ''
    );
  }

  vehicleUploadExcel(form: any, importStatus: number) {
    return this.baseDataService.makePostCallExcelUpload(
      `${this.apiVehicleUrl}/excelUpload?importStatus=${importStatus}`,
      form
    );
  }

  contactTemplateDownload(isTemplateEmpty: boolean): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiContactUrl}/contactTemplateDownload?isTemplateEmpty=${isTemplateEmpty}`,
      ''
    );
  }

  contactUploadExcel(form: any, importStatus: number) {
    return this.baseDataService.makePostCallExcelUpload(
      `${this.apiContactUrl}/excelUpload?importStatus=${importStatus}`,
      form
    );
  }

  fuelHistoryTemplateDownload(isTemplateEmpty: boolean): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiFuelHistoryUrl}/fuelHistoryTemplateDownload?isTemplateEmpty=${isTemplateEmpty}`,
      ''
    );
  }

  fuelHistoryUploadExcel(form: any, importStatus: number) {
    return this.baseDataService.makePostCallExcelUpload(
      `${this.apiFuelHistoryUrl}/excelUpload?importStatus=${importStatus}`,
      form
    );
  }

  plateMasterTemplateDownload(isTemplateEmpty: boolean): Observable<any> {
    return this.baseDataService.makePostDownloadCall(
      `${this.apiPlateMasterUrl}/plateMasterTemplateDownload?isTemplateEmpty=${isTemplateEmpty}`,
      ''
    );
  }

  plateMasterUploadExcel(form: any, importStatus: number) {
    return this.baseDataService.makePostCallExcelUpload(
      `${this.apiPlateMasterUrl}/excelUpload?importStatus=${importStatus}`,
      form
    );
  }
}
